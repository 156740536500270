import tefeImg from "../assets/img/Tefe.png";
import bintaImg from "../assets/img/Binta.png";
import barakatImg from "../assets/img/Barakat.png";
import dorcasImg from "../assets/img/Dorcas.png";
import pearlImg from "../assets/img/Pearl.png";
import jumaiImg from "../assets/img/Jumai.png";
import danielaImg from "../assets/img/Daniela.png";
import afolabiImg from "../assets/img/Afolabi.png";
import joelImg from "../assets/img/Joel.png";
import preciousImg from "../assets/img/Precious.png";
import chiomaImg from "../assets/img/Chioma.png";
import damilolaImg from "../assets/img/Damilola.png";
import celestinaImg from "../assets/img/2024-alumni/Celestina Amadi.png";
import ebeleImg from "../assets/img/2024-alumni/Ebele Nnacheta.png";
import greatImg from "../assets/img/2024-alumni/Great Amubode.png";
import hadizaImg from "../assets/img/2024-alumni/Hadiza Umar.png";
import ruthImg from "../assets/img/2024-alumni/Ruth Adetunji.png";
import tomiImg from "../assets/img/2024-alumni/Tomi Thomas.png";
import simiImg from "../assets/img/2024-alumni/Simi Da-Walang.png";
import eazeniImg from "../assets/img/2024-alumni/ezeani.png";

import { colors } from "../constants";

export const years = ["2021", "2022", "2023", "2024"];

export const data = {
  2021: [
    {
      name: "Barakat Ajadi",
      role: "Associate Product Manager",
      img: barakatImg,
      color: colors.turquoise,
      about: [
        `
        Barakat always knew she wanted a path in computer science and technology since her father got her a computer when she was very young. 
        She is excited about community building and the opportunity it presents to help others grow. 
          `,
        `
        At work, she’s passionate about ensuring reliability to make users happy, and her favourite things are anime, ice cream and travelling, in no particular order.
            .
          `,
      ],
    },
    {
      name: "Tefe Oladejo",
      role: "Frontend Engineer",
      img: tefeImg,
      color: colors["lite-lemon"],
      about: [
        `
            Tefe enjoys travelling and taking pictures, and she is passionate
            about leveraging front-end development to allow people limitless
            access to a good life.
          `,
        `
            She had a pretty sweet transition into tech and wrote all about it 
            <a href="https://moniepoint.com/blog/the-right-shoulder-a-story-of-beginnings-and-progress">
              here
            </a>
            .
          `,
      ],
    },
    {
      name: "Binta Umar",
      role: "Backend Engineer",
      img: bintaImg,
      color: colors.lavender,
      about: [
        `
        Binta enjoys simple and effective problem-solving. She is fascinated by how technology affects people's lives and has a knack for innovation.
          `,
        `
        She enjoys learning about healthy lifestyles, going on hikes, and seeing how her career in financial technology helps those around her with their businesses.

            .
          `,
      ],
    },
  ],
  2022: [
    {
      name: "Chioma Ibekwe",
      role: "Product Designer",
      img: chiomaImg,
      color: colors.mint,
      about: [
        `Chioma loves crafting beautiful experiences through design.
          `,
        `She had a career in Medicine & Surgery before switching to design, and she’s thrilled by the transition. She loves junk food and shows that are strange, dark and mysterious.
          `,
      ],
    },
    {
      name: "Feranmi Joel",
      role: "Technical Product Manager",
      img: joelImg,
      color: colors.turquoise,
      about: [
        `Feranmi leverages her technology and product management expertise to develop and launch successful products with a meaningful impact on people's daily lives.
          `,
        `
        She has a positive energy that inspires those around her and enjoys reading tweets, newsletters, and books (except autobiographies) outside of work. She’s also known for her quirky humour and love of classic films.
          `,
      ],
    },
    {
      name: "Damilola Omolori",
      role: "Backend Engineer",
      img: damilolaImg,
      color: colors["lite-lemon"],
      about: [
        `
        Damilola loves travelling and is always on the lookout for new dishes to try.
          `,
        `She wants to provide top-notch, innovative and user-friendly software that enhances people’s lives and addresses real-world issues. She also hopes that her work will help advance the field of software engineering.
          `,
      ],
    },
    {
      name: "Precious Uzochukwu",
      role: "Backend Engineer",
      img: preciousImg,
      color: colors["lite-peach"],
      about: [
        `
        Precious’ ultimate goal is to own a foundation that reaches out to families who have children born with hearing defects and teach them sign language to encourage communication between them.
          `,
        `
        She graduated with an honours degree in computer science, and when she’s not doing cool stuff with code, she loves to read books and play video games.
          `,
      ],
    },
  ],
  2023: [
    {
      name: "Pearl  Akpan",
      role: "Frontend Engineer",
      img: pearlImg,
      color: colors.mint,
      about: [
        `Pearl wanted to be a pilot at age 5 but changed course when she learnt that planes could crash. Today, she takes people on it's journeys through frontend engineering at Moniepoint. `,
        `
        Although Pearl doesn’t have a favourite meal, she eats all kinds of pasta. 
          `,
      ],
    },
    {
      name: "Dorcas Abang",
      role: "Technical Product Manager",
      img: dorcasImg,
      color: colors.turquoise,
      about: [
        `
        When she’s not enjoying her favourite meal of Afang soup paired with Northern Tuwo, Dorcas is passionate about using technology to create positive change.
          `,
        `She also loves to stay updated on tech trends through books and watching movies.
          `,
      ],
    },
    {
      name: "Daniela Uzosike",
      role: "Systems Administrator",
      img: danielaImg,
      color: colors["lite-lemon"],
      about: [
        `Daniela Uzosike is passionate about fixing things. She ties this passion to her love for repairing her toys and play tools as a child.
          `,
        `This early interest led her to pursue a degree in Engineering, in order to solve real-world problems. Outside of work, Daniela loves good food and music. 
          `,
      ],
    },
    {
      name: "Jumai  Tijani",
      role: "QA Engineer",
      img: jumaiImg,
      color: colors.lavender,
      about: [
        `Jumai loves to try out DIY projects that she finds interesting and is passionate about leveraging technology to improve life.
          `,
        `Her first shot at software testing was at a boot camp, and she’s been on the path since. In her free time, she reads books and newsletters.
          `,
      ],
    },
    {
      name: "Afolabi Dolapomade",
      role: "QA Engineer",
      img: afolabiImg,
      color: colors["lite-peach"],
      about: [
        `When she’s not ensuring the highest level of quality in software products, Afolabi is a pancake-flipping maestro.
          `,
        `As a kid, she dreamt of being a professional superhero, but now she explores her superpowers by writing bug-free software and saving the day, one line of code at a time.
          `,
      ],
    },
  ],
  2024: [
    {
      name: "Tomi Thomas",
      role: "Product Designer",
      img: tomiImg,
      color: colors.turquoise,
      about: [
        `Tomi believes cake is a love language, but her passion for solving problems and designing for users is just as strong. Inspired by her sibling, a graphic designer, she was drawn to product design for its blend of creativity and user focus.`,
        `Outside of work, Tomi enjoys watching anime—always at 1.5x speed because, in her words, “life’s too short for slow pacing.” `,
      ],
    },
    {
      name: "Hadiza Umar",
      role: "Backend Engineer",
      img: hadizaImg,
      color: colors.lavender,
      about: [
        `Hadiza once dreamed of becoming a neurosurgeon, inspired by Ben Carson’s Gifted Hands, but life led her to tech, where she now creates impactful solutions as a Backend Engineer at Moniepoint.`,
        `A proud northerner, female, and Java engineer, Hadiza thrives in challenging environments and loves proving that women belong in tech. Outside of work, she dances to unwind and never says no to yam and egg—her ultimate comfort food.`,
      ],
    },
    {
      name: "Ruth Adetunji",
      role: "Product Manager",
      img: ruthImg,
      color: colors["lite-lemon"],
      about: [
        `Ruth’s journey into tech began at a Women in Engineering workshop during her time at Obafemi Awolowo University, where inspiring stories of trailblazing women shifted her perspective and sparked her interest in making an impact. Now an Associate Product Manager at Moniepoint, she thrives on meaningful contributions and the energy of her amazing team.`,
        `A proud Jesus baby, Ruth loves movies, pounded yam, and creating lasting positive impacts in every space she finds herself.`,
      ],
    },
    {
      name: "Ebele Nnacheta",
      role: "Product Manager",
      img: ebeleImg,
      color: colors["lite-peach"],
      about: [
        `Ebele studied accounting in university but found herself pulled into tech when the COVID lockdown flipped the script. Now a Product Manager at Moniepoint, she enjoys the diversity of knowledge her role offers—working with almost everyone and learning a little bit of everything along the way.`,
        `Ebele is a mix of sunshine and steel, a natural dancer, and a creative soul with plenty of handy skills. Her ultimate comfort food? Oil rice and eggs—simple and undefeated!`,
      ],
    },
    {
      name: "Great Amubode",
      role: "Frontend Engineer",
      img: greatImg,
      color: colors.turquoise,
      about: [
        `Great’s tech journey took off during COVID when she realized she loved working on new projects, picking up skills, and seeing her contributions come to life—plus, the money doesn’t hurt. She once dreamed of being an astronaut, but her fear of heights—and Nigeria’s lack of a space program—kept her grounded.`,
        `Surrounded by extrovert energy throughout the day, Great recharges her introverted social battery by knitting or sticking to her new workout routine. One day, she plans to conquer her fear of heights and try skydiving and snowboarding.`,
      ],
    },
    {
      name: "Simi Da\u2011Walang",
      role: "Frontend Engineer",
      img: simiImg,
      color: colors.lavender,
      about: [
        `The balance of discipline and artistry has always appealed to Simi—first through her childhood fascination with gymnastics and now as a Frontend Engineer at Moniepoint. A highly visual person, she enjoys blending technical skills with creativity, experimenting with animations, transitions, and micro-interactions to design engaging user experiences.`,
        `Outside of work, she enjoys lunch dates with friends, trying her hand at archery, and devouring tacos—her ultimate comfort food. In one word? Chill.`,
      ],
    },
    {
      name: "Melodie Ezeani",
      role: "Data Engineer",
      img: eazeniImg,
      color: colors.mint,
      about: [
        `Melodie once dreamed of being a spy, but instead, she now uncovers insights as a Data Engineer. She loves the process of solving data challenges with logic and creativity, a passion that began during her time as a data analyst.`,
        `Outside work, Melodie enjoys craft activities like sewing and painting and unwinds by watching anime with a plate of pasta nearby. She describes herself as brilliant—like light, bringing clarity and brightness wherever she goes.`,
      ],
    },
    {
      name: "Celestina Amadi",
      role: "Cloud Engineer",
      img: celestinaImg,
      color: colors.turquoise,
      about: [
        `Celestina’s curiosity and love for problem-solving led her to a career in tech. She thrives on tackling challenges and building impactful solutions, channeling her childhood dream of teaching through knowledge-sharing in her work.`,
        `A proud plant mom with two green beauties, Celestina recharges with her ultimate comfort food—plantain and egg.`,
      ],
    },
  ],
};
