export const MOBILE_WIDTH_THRESHOLD = 800;
export const colors = {
  "lite-lemon": "rgba(214, 242, 147, 1)",
  lavender: "rgba(228, 214, 253, 1)",
  turquoise: "rgba(196, 233, 253, 1)",
  mint: "rgba(226, 255, 246, 1)",
  "lite-almond": "rgba(255, 241, 198, 1)",
  "lite-peach": "rgba(255, 224, 204, 1)",
  "deep-maroon": "rgba(230, 222, 223, 1)",
  "powder-blue-gray": "rgba(220, 227, 226, 1)",
  "blue-alt": "rgba(225, 235, 253, 1)",
};
export const teamCount = "nine";
export const womenCount = "15";
export const pageSections = {
  about: "about",
  requirements: "requirements",
  alumni: "alumni",
  faqs: "faqs",
};
