import React, { useState } from "react";
import { years } from "../../../../../common/data/alumunis";
import ProfileList from "../ProfileList";
import "./style.css";
import WitButton from "../../../../../common/component/WitButton";

function MobileProfileList({ activeYear, handleActiveYear }) {
  const [showAll, setShow] = useState(false);

  const handleShowAll = (year) => {
    if (year === activeYear) {
      setShow(!showAll);
    } else {
      handleActiveYear(year);
      if (!showAll) {
        setShow(true);
      }
    }
  };

  return (
    <div>
      {years.map((year, idx) => (
        <div
          className={`alum-year-wrap ${
            showAll && year === activeYear ? "show-all" : ""
          }`}
          key={idx}
        >
          <div className="alum-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="mb-alumini-year">{year}</div>
              <div className="alum-toggle-btn-wrap">
                <WitButton
                  text={showAll && activeYear === year ? "COLLAPSE" : "EXPAND"}
                  handleClick={() => handleShowAll(year)}
                />
              </div>
            </div>
          </div>
          <ProfileList
            isMobile={true}
            current={year}
            activeYear={activeYear}
            handleActiveYear={handleActiveYear}
            handleShowAll={handleShowAll}
          />
        </div>
      ))}
    </div>
  );
}

export default MobileProfileList;
