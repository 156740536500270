import React from "react";
import chevronUp from "../../assets/icons/chevron-up.svg";
import linkedInImg from "../../assets/icons/linkedln.svg";
import instagramImg from "../../assets/icons/instagram.svg";
import twitterImg from "../../assets/icons/twitter.svg";
import "./styles.css";

function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const handleScrollUp = (id) => {
    const target = window.document.getElementById(id);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer className="wit-footer py-4 mt-5">
      <div className="wit-container d-flex flex-wrap justify-content-between wit-footer-wrap">
        <div className="wit-order order-md-2 d-flex justify-content-center">
          <span
            className="wit-scroll d-flex align-items-center justify-content-center"
            id="scroll-up"
            onClick={() => handleScrollUp("about")}
          >
            <img className="wit-bounce-up" src={chevronUp} alt="arrow up" />
          </span>
        </div>
        <div className="wit-order wit-order-2 d-flex align-items-center wit-footer-item order-md-1">
          <ul className="d-flex justify-content-between social-icons">
            <li>
              <a
                href="https://instagram.com/moniepoint?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <img src={instagramImg} alt="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/moniepoint?s=21&t=X9uK--XPZTEG8xqc8gHO-A"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <img src={twitterImg} alt="twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/moniepoint-inc/"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <img src={linkedInImg} alt="linkedIn" />
              </a>
            </li>
          </ul>
          <ul className="d-flex justify-content-around policy">
            <li>
              <a href="https://moniepoint.com/ng/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/">Terms &amp; Conditions</a>
            </li>
          </ul>
        </div>

        <div className="d-lg-flex wit-order-3 order-md-3">
          <p>©{currentYear} Moniepoint Inc. All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
