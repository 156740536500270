import React, { useState, useRef } from "react";
import { data, years } from "../../../../../common/data/alumunis";
import { colors } from "../../../../../common/constants";
import ProfileCard from "./ProfileCard";
import WitButton from "../../../../../common/component/WitButton";

function ProfileList({
  activeYear,
  current,
  handleActiveYear,
  isMobile = false,
  handleShowAll,
}) {
  const [visibleProfile, setVisibility] = useState(-1);
  const containerRef = useRef(null);
  const bgColor = data[activeYear]?.[visibleProfile]?.color;

  const handleProfileDetails = (id) => {
    if (id === visibleProfile) {
      setVisibility(-1);
    } else {
      setVisibility(id);
    }
    if (isMobile && handleShowAll) {
      handleShowAll();
    }
  };

  const getColorLabel = (colorCode) =>
    Object.keys(colors).find((key) => colors[key] === colorCode);

  const scrollLeft = () => {
    const container = containerRef.current;
    if (!container) return;
    if (container.scrollLeft > 0) {
      container.scrollBy({ left: -700, behavior: "smooth" });
    } else {
      const prev = (+activeYear - 1).toString();
      handleScrollEffect(prev);
    }
  };

  const handleScrollEffect = (year) => {
    if (years.includes(year)) {
      handleActiveYear(year);
      const target = window.document.getElementById(year);
      if (target && !isMobile) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const scrollRight = () => {
    const container = containerRef.current;
    const maxScrollLeft = container.scrollWidth - container.clientWidth;

    if (container.scrollLeft < maxScrollLeft) {
      container.scrollBy({ left: 700, behavior: "smooth" });
    } else {
      const next = (+activeYear + 1).toString();
      handleScrollEffect(next);
    }
  };

  return (
    <>
      {(isMobile || activeYear === current) && (
        <div className="alum-list-wrapper" style={{ background: bgColor }}>
          {!isMobile && (
            <div className="d-flex wit-ctrls animate__animated animate__fadeIn delay-1">
              <WitButton
                text={"Previous"}
                handleClick={scrollLeft}
                witClass="prev"
              />
              <div className="alumini-year active d-flex align-items-center justify-content-center">
                {activeYear}
              </div>
              <WitButton
                text={"Next"}
                handleClick={scrollRight}
                witClass="Next"
              />
            </div>
          )}
          <div
            className="wit-scroller alum-scroll-wrap"
            ref={containerRef}
            id={current}
            key={current}
          >
            {data[current] &&
              data[current].map((profile, index) => {
                return (
                  <ProfileCard
                    {...profile}
                    id={index}
                    isVisible={visibleProfile === index}
                    colorLabel={getColorLabel(profile.color)}
                    handleProfileDetails={handleProfileDetails}
                    isMobile={isMobile}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileList;
