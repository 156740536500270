import React from "react";
import "./style.css";
import requirements from "../../data/requirements";
import bulletIcon from "../../assets/img/star-bullet.svg";
import { teamCount } from "../../constants";
import WitButton from "../WitButton";

function WitApplyModal({ visible, handleClose }) {
  return (
    <div
      className={`animate__animated animate__fadeInDown wit-modal-wrap ${
        visible ? "show-modal" : ""
      }`}
      id="apply-modal"
    >
      <div
        className="wit-apply-modal animate__animated animate__fadeInRight delay-2"
        id="modal-content"
      >
        <div className="wit-modal-content ms-4">
          <div className="wit-available-careers mx-auto">
            <span
              className="btn btn-link float-end text-danger"
              id="close-modal"
              onClick={handleClose}
            >
              <b>CLOSE</b>
            </span>
            <div className="wit-modal-body">
              <div className="my-5 wit-modal-header">
                <h2>Apply Now</h2>
                <p className="mt-2">
                  Choose from any of the {teamCount} teams below to start your
                  journey
                </p>
              </div>
              {requirements.map((requirement, idx) => {
                return (
                  <div
                    className="accordion accordion-flush wit-career-accordion"
                    id={`apply-${idx}`}
                    key={idx}
                  >
                    <div className="accordion-item mb-3">
                      <div className="accordion-header" id="flush-headingOne">
                        <div
                          className="accordion-button wit-accordion-header collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target={`#roles-${idx}`}
                          aria-expanded="false"
                          aria-controls={`roles-${idx}`}
                        >
                          <span className="wit-accordion-img-span">
                            <img
                              src={requirement.icon}
                              alt={requirement.role}
                              className="img-fluid"
                            />
                          </span>
                          <b className="w-80 role-title">{requirement.role}</b>
                        </div>
                      </div>
                      <div
                        id={`roles-${idx}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent={`#apply-${idx}`}
                      >
                        <div className="accordion-body">
                          <ul>
                            {requirement.description.map((value, id) => (
                              <li className="d-flex align-items-start" key={id}>
                                <img
                                  src={bulletIcon}
                                  className="me-3 mt-2"
                                  alt="bullet point"
                                />
                                <p>{value}</p>
                              </li>
                            ))}
                          </ul>
                          <div className="text-center py-3">
                            <WitButton
                              text={"SUBMIT APPLICATION"}
                              link={requirement.applicationLink}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WitApplyModal;
