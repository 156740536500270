import React from "react";
import "./style.css";
import Description from "./Description";
import { pageSections } from "../../../../common/constants";
import tl1Img from "../../../../common/assets/img/tl1-abt-lens.svg";
import tl2Img from "../../../../common/assets/img/tl2-abt-dark-laptop.svg";
import tl3Img from "../../../../common/assets/img/tl3-abt-cube.svg";
import tr1Img from "../../../../common/assets/img/tr1-abt-desktop.svg";
import tr2Img from "../../../../common/assets/img/tr2-abt-small-cube.svg";
import tr3Img from "../../../../common/assets/img/tr3-abt-laptop.svg";

function About() {
  return (
    <div id={pageSections.about}>
      <Intro />
      <Description />
    </div>
  );
}

export default About;

const Intro = () => (
  <>
    <section className="wit-section-container wit-about-intro-sect">
      <div className="abt-bg-container">
        <img
          className="abt-deco tl1 animate__animated animate__fadeInLeft"
          src={tl1Img}
          alt="bg-decoration"
        />
        <img
          className="abt-deco tl2 animate__animated animate__fadeInLeft delay-1"
          src={tl2Img}
          alt="bg-decoration"
        />
        <img
          className="abt-deco tl3 animate__animated animate__fadeInLeft delay-2"
          src={tl3Img}
          alt="bg-decoration"
        />
        <img
          className="abt-deco tr1 animate__animated animate__fadeInRight"
          src={tr1Img}
          alt="bg-decoration"
        />
        <img
          className="abt-deco tr2 animate__animated animate__fadeInRight delay-1"
          src={tr2Img}
          alt="bg-decoration"
        />
        <img
          className="abt-deco tr3 animate__animated animate__fadeInRight delay-2"
          src={tr3Img}
          alt="bg-decoration"
        />
      </div>

      <div className="h-100 d-flex wit-about-div about-intro">
        <div className="page-section animate__animated animate__fadeInUp">
          <div
            className="wit-about text-center about-descr d-flex"
            id="about-head"
          >
            <p>
              The Moniepoint Women in Tech Internship is aimed at ensuring that
              young women have access to building a career in technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);
