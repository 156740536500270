import React from "react";
import "./styles.css";

function WitButton({ text = "", handleClick, witClass = "", link }) {
  const content = <span className="fw-bold">{text}</span>;

  return link ? (
    <a
      href={link}
      className={`btn btn-wit-apply ${witClass}`}
      target="_blank"
      rel="noreferrer"
    >
      {content}
    </a>
  ) : (
    <button className={`btn btn-wit-apply ${witClass}`} onClick={handleClick}>
      {content}
    </button>
  );
}

export default WitButton;
