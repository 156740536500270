import React, { useState, useRef } from "react";
import "./style.css";
import { years } from "../../../../common/data/alumunis";
import ProfileList from "./ProfileList";
import MobileProfileList from "./MobileProfileList";
import { pageSections } from "../../../../common/constants";

function Alumni() {
  const [activeYear, setActiveYear] = useState("2021");
  const containerRef = useRef(null);

  return (
    <section
      className="alumini-section dynamic-bg pb-5 full-height wit_section"
      data-section={pageSections.alumni}
      id={pageSections.alumni}
      ref={containerRef}
    >
      <div>
        <div className="alumin-container wit-header-wrap wit-requirement-section d-flex justify-content-center w-100 wit-container">
          <div>
            <div
              className="wit-requirement-hero wit_animate"
              id="wit-success-text"
              data-wit-animate="animate__animated animate__fadeInUp"
            >
              <span className="wit-requirement-heading h1">
                {" "}
                The WIT Internship Alumni
              </span>
              <sub className="wit-small-text">
                Meet the previous Women In Tech interns, who are now working in
                full-time roles at Moniepoint.
              </sub>
            </div>
          </div>
        </div>
        <div className="page-section wit_section">
          <div>
            <div className="for-desktop">
              <div
                className="profile-card-list-wrap"
                id="success-cards"
                style={{ position: "relative" }}
              >
                {years.map((year) => (
                  <div className="alum-year-wrap" key={year}>
                    <div
                      onClick={() => setActiveYear(year)}
                      className="alumini-year align-items-center justify-content-center"
                      style={{
                        display: activeYear === year ? "none" : "flex",
                      }}
                    >
                      {year}
                    </div>

                    <ProfileList
                      current={year}
                      activeYear={activeYear}
                      handleActiveYear={setActiveYear}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="for-mobile">
              <MobileProfileList
                activeYear={activeYear}
                handleActiveYear={setActiveYear}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Alumni;
