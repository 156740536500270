import React, { useRef } from "react";
import "./style.css";
import RequirementCard from "../../../../common/component/RequirementCard";
import useHorizontalScroll from "../../../../common/hooks/useHorizontalScroll";
import data from "../../../../common/data/requirements";
import { pageSections } from "../../../../common/constants";
import { hasTouch } from "../../../../common/utils";

const PREFERRED_DESCRIPTION_LENGTH = 6;

const adjustDescriptionLength = (description) =>
  description.slice(
    0,
    Math.min(description.length, PREFERRED_DESCRIPTION_LENGTH)
  );

function Requirement() {
  const isTouchUser = hasTouch();
  const componentRef = useRef(null);
  const sliderRef = useRef(null);

  useHorizontalScroll(".wit-req-card", componentRef, sliderRef);

  return (
    <section id={pageSections.requirements}>
      <div>
        <div className="wit-day">
          <div
            className="wit-requirement-hero wit-header-wrap"
            id="wit-req-text"
          >
            <span className="wit-requirement-heading h1">
              {" "}
              A day in the life of an intern
            </span>
            <sub className="wit-small-text">
              What you will be responsible for as a Women-in-Tech intern.
            </sub>
          </div>
        </div>
        <div
          className="page-section req-outer"
          style={{ overflow: "hidden" }}
          ref={componentRef}
        >
          <div className="req-slide" style={{ margin: "auto", width: "100%" }}>
            <div
              ref={sliderRef}
              className={`wit-requirements wit-scroller about-scroll-wrap pt-5 ${isTouchUser ? "normal-horizontal-scroll" : ""}`}
              id="horizontalScrollingReq"
            >
              {data.map(({ description, ...requirement }, id) => (
                <RequirementCard
                  key={id}
                  {...requirement}
                  description={adjustDescriptionLength(description)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Requirement;
