import React from "react";
import bullet from "../../assets/img/star-bullet.svg";
import WitButton from "../WitButton";

function RequirementCard({ role, description, applicationLink, icon, color }) {
  const classNameRole = role.toLowerCase().replace(/ /g, "-");
  return (
    <div
      className={`panel card wit-req-card pb-5 wit-sys-admin ${classNameRole}`}
      style={{ background: color ? color : "" }}
    >
      <div>
        <div className="pb-2">
          <h1>
            {role.split(" ").map((word, idx) => (
              <span key={idx}>
                {word}
                <br />
              </span>
            ))}
          </h1>
        </div>
        <ul>
          {description.map((info, id) => (
            <li className="d-flex align-items-start" key={id}>
              <img src={bullet} className="me-3 mt-2" alt="bullet point" />
              <p>{info}</p>
            </li>
          ))}
        </ul>
        <div className="text-center pb-3">
          <WitButton text={"SUBMIT APPLICATION"} link={applicationLink} />
        </div>
      </div>
      <div className="wit-req-card-img">
        <img src={icon} alt={role} />
      </div>
    </div>
  );
}

export default RequirementCard;
