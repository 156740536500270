import React, { useEffect, useState } from "react";
import navLogo from "../../assets/img/Moniepoint-Logo-wit.svg";
import { navList } from "../../data/menuData";
import useObserver from "../../hooks/useObserver";

function Header() {
  const sectionInView = useObserver();
  const [activeElem, setActiveElem] = useState(navList[0]);
  useEffect(() => {
    setActiveElem(sectionInView);
  }, [sectionInView]);

  return (
    <header
      className="fixed-top animate__animated animate__fadeInDown delay-1"
      id="wit-nav"
    >
      <nav className="navbar navbar-expand-lg navbar-light wit-nav">
        <a href="/" className="moniepoint-logo">
          <img src={navLogo} alt="logo" />
        </a>
        <div
          className="collapse navbar-collapse d-lg-flex justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ">
            {navList.map((nav) => (
              <li className="nav-item" key={nav}>
                <a
                  className={`nav-link ${activeElem === nav ? "active" : ""}`}
                  onClick={() => setActiveElem(nav)}
                  href={`#${nav}`}
                >
                  {nav}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="text-end">
          <a
            href="https://moniepoint.com/careers"
            target="_blank"
            rel="noreferrer"
            className="careers-link text-uppercase text-decoration-underline"
          >
            Moniepoint Careers
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
