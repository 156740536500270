import React, { useRef, useState } from "react";
import "./style.css";
import WitModal from "../../../../../common/component/WitApplyModal";
import useHorizontalScroll from "../../../../../common/hooks/useHorizontalScroll";
import womenImg from "../../../../../common/assets/img/people.png";
import clockImg from "../../../../../common/assets/img/time.png";
import perksImg from "../../../../../common/assets/img/perks.png";
import roles from "../../../../../common/data/roles";
import { teamCount, womenCount } from "../../../../../common/constants";
import WitButton from "../../../../../common/component/WitButton";
import { hasTouch } from "../../../../../common/utils";

function Description() {
  const [isVisible, setVisible] = useState(false);
  const componentRef = useRef(null);
  const sliderRef = useRef(null);
  const isTouchUser = hasTouch();

  useHorizontalScroll(".gsap-about", componentRef, sliderRef);

  const handleModal = () => {
    setVisible(!isVisible);
  };

  return (
    <section>
      <div>
        <div
          className="page-section req-outer"
          style={{ overflow: "hidden" }}
          ref={componentRef}
        >
          <div className="req-slide" style={{ margin: "auto", width: "100%" }}>
            <div
              ref={sliderRef}
              className={`wit-scroller about-scroll-wrap pt-5  ${isTouchUser ? "normal-horizontal-scroll" : ""}`}
              id="horizontalScrollingReq"
            >
              <div className="panel card about-card wit-women flex gsap-about">
                <div className="wit-card-text">
                  <h1>{womenCount} Women</h1>
                  <p>
                    Each intern will get direct mentorship from an industry
                    expert in their field
                  </p>
                </div>
                <div className="wit-img-wrap">
                  <img
                    className="img-fluid wit-women-lg"
                    src={womenImg}
                    alt="five women"
                  />
                </div>
              </div>
              <div className="panel card about-card wit-team gsap-about">
                <div className="wit-card-text">
                  <h1>Different Teams</h1>
                  <p>
                    Choose from any of the {teamCount} teams below to start your
                    awesome career journey
                  </p>
                </div>
                <div className="wit-img-wrap wit-img-group d-flex">
                  {roles.map((role, idx) => (
                    <img
                      className="img-fluid"
                      src={role.image}
                      alt={role.title}
                      key={idx}
                    />
                  ))}
                </div>
              </div>
              <div className="panel about-card wit-card-group d-flex gsap-about">
                <div className="card about-card wit-duration">
                  <div className="wit-card-text pb-4">
                    <h1>6 Month Long Internship</h1>
                    <p>
                      Spend time working on{" "}
                      <span className="real-projects"> real projects </span> to
                      help shape finance in Africa.
                    </p>
                  </div>
                  <div className="wit-card-group-img wit-card-top-img">
                    <img src={clockImg} alt="clock" />
                  </div>
                </div>
                <div className="card about-card wit-perks">
                  <div className="wit-card-text d-flex flex-column justify-content-between h-100">
                    <h1>Awesome Perks</h1>
                    <div>
                      <div className="perks d-md-flex justify-content-between">
                        <div>
                          <p className="d-flex">
                            <span>+</span> Nice salary
                          </p>
                          <p className="d-flex">
                            {" "}
                            <span>+</span> Learn from industry experts
                          </p>
                        </div>
                        <div>
                          <p className="d-flex">
                            {" "}
                            <span>+</span> Cool merch
                          </p>
                          <p className="d-flex">
                            <span>+</span> Work tools
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="perks-bonus">
                        And a shot at improving the experience for over 70
                        million people that use our POS terminals in Nigeria ***
                      </p>
                    </div>
                  </div>
                  <div className="wit-card-group-img  wit-card-bottom-img">
                    <img src={perksImg} alt="bag of goodies" />
                  </div>
                </div>
              </div>
              <div className="panel card about-card wit-leap text-center gsap-about">
                <div className="text-white wit-card-text">
                  <h1>Take the leap</h1>
                  <p className="text-white">
                    Come get hands-on experience building the future of finance
                    in Africa
                  </p>
                  <div className="mt-5">
                    <WitButton text="APPLY NOW" handleClick={handleModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <WitModal visible={isVisible} handleClose={handleModal} />
      </div>
    </section>
  );
}

export default Description;
