import React, { useEffect, useState, useCallback, useRef } from "react";
import { Helmet } from "react-helmet-async";
import useObserver from "../../hooks/useObserver";
import { useLocation } from "react-router-dom";
import { MOBILE_WIDTH_THRESHOLD } from "../../constants";

const PageWrapper = ({ children, load }) => {
  const sectionInView = useObserver();
  const [isLoading, setIsLoading] = useState(true);
  const hasScrolled = useRef(false);
  const location = useLocation();

  const canRedirect =
    !hasScrolled.current &&
    location.hash &&
    window.matchMedia?.(
      `only screen and (min-width: ${MOBILE_WIDTH_THRESHOLD}px)`
    ).matches;

  const scrollToTarget = useCallback(() => {
    const hash = location.hash.replace("#", "");
    if (!hash || !canRedirect) return;

    const target = document.getElementById(hash);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
      hasScrolled.current = true;
    }
  }, [location, canRedirect]);

  useEffect(() => {
    if (canRedirect) {
      setTimeout(scrollToTarget, 300);
    }
  }, [scrollToTarget, canRedirect]);

  useEffect(() => {
    if (load) {
      const timer = setTimeout(() => setIsLoading(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [load]);

  return (
    <>
      <Helmet title={sectionInView || "Home"} />
      <div className={`wit-pg-${sectionInView}`}>
        {load && isLoading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <div className="spinner-grow" role="status"></div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default PageWrapper;
