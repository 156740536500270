import { useState, useEffect } from "react";
import { navList } from "../data/menuData";

const defaultOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
};

const useObserver = (options = defaultOptions) => {
  const [sectionInView, setSectionInView] = useState("");

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setSectionInView(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    navList.forEach((elementId) => {
      const targetElement = document.getElementById(elementId);
      if (targetElement) {
        observer.observe(targetElement);
      }
    });

    return () => {
      navList.forEach((elementId) => {
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
          observer.unobserve(targetElement);
        }
      });
    };
  }, [options]);

  return sectionInView;
};

export default useObserver;
