import sysAdminImg from "../assets/img/jd-sys-admin.svg";
import beImg from "../assets/img/jd-be.svg";
import feImg from "../assets/img/jd-fe.svg";
import deImg from "../assets/img/jd-de.svg";
import tpmImg from "../assets/img/jd-tpm.svg";
import qaImg from "../assets/img/jd-qa.svg";
import techSuppImg from "../assets/img/jd-tech-supp.svg";
import mobileImg from "../assets/img/jd-mobile.svg";
import ceImg from "../assets/img/jd-ce.svg";
import { colors } from "../constants";

const requirements = [
  {
    role: "System Administrator",
    description: [
      "Responsible for maintaining, configuring, and reliably operating computer systems and network servers.",
      "Upgrading, installing, and configuring application software and computer hardware.",
      "Troubleshooting system errors",
      "Providing technical support to employees in a timely manner with limited disruptions.",
      "Work collaboratively in a team environment.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4130648101",
    icon: sysAdminImg,
    color: colors["lite-lemon"],
  },
  {
    role: "Backend Engineer",
    description: [
      "Translates product requirements into architectural documents and diagrams.",
      "Apply engineering principles to solve complex problems through sound and creative engineering.",
      "Learn new engineering methods and incorporate them into your work processes.",
      "Demonstrates skill in time management and completing software projects in a cooperative team environment.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4024838101",
    icon: beImg,
    color: colors.lavender,
  },
  {
    role: "Data Engineer",
    description: [
      "Work with stakeholders throughout the organization to identify opportunities for leveraging company data to drive business solutions.",
      "Mine and analyze data from company databases to drive optimization and improvement of product development, marketing techniques and business strategies.",
      "Assess the effectiveness and accuracy of new data sources and data gathering techniques.",
      "Develop custom data models and algorithms to apply to data sets.",
      "Use predictive modeling to increase and optimize customer experiences, revenue generation, ad targeting and other business outcomes.",
      "Develop company A/B testing framework and test model quality.",
      "Coordinate with different functional teams to implement models and monitor outcomes",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4304254101",
    icon: deImg,
    color: colors.turquoise,
  },
  {
    role: "Product Manager",
    description: [
      "Work with the engineering team to create and modify products to fulfil customer needs.",
      "Gain a deep understanding of customer experience, identify and fill product gaps and generate new ideas",
      "Work with internal teams, including developers, engineers, architects, quality assurance, and operations",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4304275101",
    icon: tpmImg,
    color: colors.mint,
  },
  {
    role: "Mobile Engineer",
    description: [
      "Assist in developing and improving mobile apps (iOS & Android).",
      "Fix bugs, optimise performance, and maintain the mobile codebase.",
      "Learn and apply industry best practices in mobile development.",
      "Contribute to architectural discussions on scalability, performance, and security.",
      "Collaborate with engineers and participate in team discussions.",
      "Engage in code reviews to enhance code quality.",
      "Support testing and quality assurance efforts.",
      "Contribute to technical documentation.",
      "Help identify and resolve issues during development and deployment.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4553943101",
    icon: mobileImg,
    color: colors["lite-almond"],
  },
  {
    role: "Frontend Engineer",
    description: [
      "Develop high-quality software design and architecture.",
      "Identify, prioritize and execute tasks in the software development life cycle.",
      "Develop tools and applications by producing clean, efficient code.",
      "Applies engineering principles to solve complex problems through sound and creative engineering.",
      "Learn new engineering methods and incorporate them into your work processes.",
      "Demonstrates skill in time management and completing software projects in a cooperative team environment.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4130639101",
    icon: feImg,
    color: colors["powder-blue-gray"],
  },
  {
    role: "Technical Support Engineer",
    description: [
      "Provide tier two application support to production systems and identify any issue in production.",
      "Taking ownership of customer issues reported and seeing problems through to resolution.",
      "Collaborate with product and engineering teams to fix bugs.",
      "Monitor performance metrics for various production systems, identify root cause for all technical issues and work with the engineering team to resolve them.",
      "Manage team ticket queue and resolve in a timely manner.",
      "Develop and maintain accurate technical, software operations and support related documentation.",
      "Work with software vendors to have application issues fixed, both short term and long term (root cause).",
      "Act as a technical resource during other projects as required.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4304243101",
    icon: techSuppImg,
    color: colors["blue-alt"],
  },
  {
    role: "Cloud Engineering",
    description: [
      "Assist in the setup, configuration, and maintenance of cloud infrastructure using GCP.",
      "Support the management of Kubernetes clusters, including deployment, scaling, and monitoring.",
      "Contribute to the design and implementation of CI/CD pipelines for automated application deployment.",
      "Work on infrastructure as code (IaC) using tools such as Terraform or Ansible to automate cloud infrastructure provisioning and management.",
      "Collaborate with the development team to ensure seamless integration of applications in the cloud environment.",
      "Contribute to the implementation of monitoring and logging solutions for cloud-based systems.",
      "Participate in security best practices and compliance standards implementation for cloud infrastructure and applications.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4304207101",
    icon: ceImg,
    color: colors["lite-peach"],
  },
  {
    role: "Quality Assurance Engineering",
    description: [
      "Work with other developers, enterprise architects,  product and project managers in designing software solutions.",
      "Perform the different types of tests required to certify the quality of an application before deployment.",
      "Maintains records of bugs captured for applications.",
      "Applies engineering principles to solve complex problems through sound and creative engineering.",
    ],
    applicationLink:
      "https://job-boards.eu.greenhouse.io/moniepoint/jobs/4024975101",
    icon: qaImg,
    color: colors["deep-maroon"],
  },
];

export default requirements;
