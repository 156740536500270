import dataEngineerImg from "../assets/img/teams/roles-de.svg";
import sysAdminImg from "../assets/img/teams/roles-sys-admin.svg";
import pmImg from "../assets/img/teams/Product-management.svg";
import beImg from "../assets/img/teams/roles-be.svg";
import ceImg from "../assets/img/teams/roles-ce.svg";
import techSuptImg from "../assets/img/teams/roles-tech-supt.svg";
import feImg from "../assets/img/teams/roles-fe.svg";
import qaImg from "../assets/img/teams/QA-engineering.svg";
import mobileImg from "../assets/img/teams/Mobile-engineering.svg";

const roles = [
  {
    title: "Systems Administrator",
    image: sysAdminImg,
  },
  {
    title: "Backend Engineer",
    image: beImg,
  },
  {
    title: "Data Engineer",
    image: dataEngineerImg,
  },
  {
    title: "Product Manager",
    image: pmImg,
  },
  {
    title: "Cloud Engineer",
    image: ceImg,
  },
  {
    title: "QA Engineering",
    image: qaImg,
  },
  {
    title: "Technical Support",
    image: techSuptImg,
  },
  {
    title: "Frontend Engineer",
    image: feImg,
  },
  {
    title: "Mobile Engineer",
    image: mobileImg,
  },
];
export default roles;
